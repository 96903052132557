<template>
<div>
    <div class="wrap">
        <div class="con_top">
            <h2><span>■</span> B2B 수납관리 수정</h2>
        </div>
        <div class="con">
            <div class="con_table">
                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                    <tbody>
                        <tr>
                            <th style="width:111px;">기업명 <span class="red">*</span></th>
                            <td class="left ml-20" colspan="3">
                               <pre>    {{name}}</pre>
                            </td>
                        </tr>
                        <tr>
                            <th>수납일자 <span class="red">*</span></th>
                            <td class="left">
                                <crm-datepicker class="ml-20" v-model="purDate" :parentDate="purDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            </td>
                            <th style="width:111px;">수납금액 <span class="red">*</span></th>
                            <td class="left">
                                <select v-model="status" class="w-120px ml-20" disabled>
                                    <option value="수납">수납</option>
                                    <option value="환불">환불</option>
                                    <option value="미수금">미수금</option>
                                </select>
                                <input type="text" v-model="purPrice" class="ml-20" style="width:200px;" value=""> 원<br>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00" style="border-top: none;">
                    <tbody>
                        <tr>
                            <th style="width: 111px;">메모</th>
                            <td class="left">
                                <textarea v-model="memo" type="text" class="ml-20 w-98per h-100px" id="introduce" name="introduce" value="" />
                                </td>
                        </tr>
                            </tbody>
                        </table>

                <div class="btns3 mgB80">
                    <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                    <a class="btn_cancle pointer" @click="modify()">수정</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
import {
    EmptyCheck
} from '@/utils/validation.js'
export default {
    data: () => ({
        idx: -1,
        idxCrmClient: -1,
        purDate: '', // 상담날짜
        purPrice: '', // 수납금액
        status: '수납',
        name: '', // 고객명
        memo: '',
        idxCrmCompany: -1,

    }),

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getPurchase(this.idx)
    },

    methods: {

        // 고객 전체목록 호출
        getPurchase(idx) {
            this.axios.get(`/api/v1/client/pur/${idx}`)
                .then(res => {                    
                    if (res.data.err === 0) {
                        this.idx = res.data.purchase.idx
                        this.idxCrmClient = res.data.purchase.idx_crm_client
                        this.name = res.data.purchase.name
                        this.purDate = res.data.purchase.pur_date
                        this.status = res.data.purchase.status
                        this.purPrice = res.data.purchase.pur_price
                        this.memo = res.data.purchase.memo
                        
                    } else {
                        alert("값을 확인해 주세요.")
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if(err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 이전 페이지로 돌아가기
        cancel() {
            //this.$router.push('/origin/customer_acceptance_b2c')
            history.back()
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        // 수정하기
        modify() {
            
            if (!EmptyCheck(this.purPrice + '', '수납금액을')) {
                return false
            }
             
            if (!EmptyCheck(this.formatDate(this.purDate), '수납일자를')) {
                return false
            }

            var vo = {
                purDate: this.formatDate(this.purDate),
                memo: this.memo,
                name: this.name,
                idxCrmClient: this.idxCrmClient,
                status: this.status,
                purPayWay: this.purPayWay,
                idxCrmCompany: this.idxCrmCompany,
                purPrice: this.purPrice
            }

            this.axios.put(`/api/v1/client/pur/${this.idx}`, vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('수정 되었습니다.')
                        //this.$router.push('/origin/customer_acceptance_b2c')
                        history.back()
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if(err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        }
    }
}
</script>